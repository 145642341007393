import { createSelector } from "reselect";
import { FieldClassification } from "../enums/FieldClassifications";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type {
    FlowItem,
    FlowErrorsByItemId,
    FlowRelation,
    FlowFilter,
    FlowCase,
    FlowItemFieldsUsed,
} from "../types/flowTypes";

import { fieldsHaveNeedsApprovalLabels } from "../helpers/needsApproval";
import { getFlowFiltersForSelectedFlow } from "./flowFilters";
import { getFlowCasesForSelectedFlow } from "./flowCases";
import { getAncestorFieldIdsForFlowItemId } from "../helpers/flowItems";
import { getOACLabelIds } from "./fieldLabels";
import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows, getAncestorsFields } from "./flowRelations";

///////// SELECTORS /////////////

export const getFlowDiscoveriesArray = createSelector(
    state => state.flowItems.byId,
    (flowItemsById: {| [number]: FlowItem |}): Array<FlowItem> => {
        const r: Array<FlowItem> = Object.values(flowItemsById);
        return r.filter(x => x.FlowItemType == "discovery");
    }
);

export type FlowDiscoveriesByItemId = {
    [number]: FlowItem,
};

export const getFlowDiscoveriesByFlowItemId = createSelector(
    state => getFlowDiscoveriesArray(state),
    (flowDiscoveries: Array<FlowItem>): FlowDiscoveriesByItemId =>
        flowDiscoveries.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowDiscoveriesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowDiscoveriesByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowDiscoveriesByItemId: FlowDiscoveriesByItemId
    ): Array<FlowItem> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const discovery = flowDiscoveriesByItemId[itemId];
            if (discovery != null) {
                result.push(discovery);
            }
        }
        return result;
    }
);

const discoveriesToErrorsById = (
    flowDiscoveries: Array<FlowItem>,
    appSettings: Array<Object>,
    flowRelations: Array<FlowRelation>,
    enabledFeatures: Array<string>,
    oacLabelIds: Array<number>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    enabledFieldLabels: { number: Array<number> },
    ancestorsFields: Array<FlowItemFieldsUsed>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowDiscovery of flowDiscoveries) {
        errorsById[flowDiscovery.FlowItemId] = validateFlowDiscovery(
            flowDiscovery,
            appSettings,
            flowRelations,
            enabledFeatures,
            oacLabelIds,
            flowFilters,
            flowCases,
            enabledFieldLabels,
            ancestorsFields
        );
    }
    return errorsById;
};

export const getDiscoveryErrorsForSelectedFlow = createSelector(
    state => getFlowDiscoveriesForSelectedFlow(state),
    state => state.appSettings.appSettings,
    state => getFlowRelationsForSelectedFlow(state),
    state => state.session.enabledFeatures,
    state => getOACLabelIds(state),
    state => getFlowFiltersForSelectedFlow(state),
    state => getFlowCasesForSelectedFlow(state),
    state => state.fieldsByCompany.enabledFieldLabels,
    state => getAncestorsFields(state),
    discoveriesToErrorsById
);

export const getDiscoveryErrorsForAllFlows = createSelector(
    state => getFlowDiscoveriesArray(state),
    state => state.appSettings.appSettings,
    state => getFlowRelationsForAllFlows(state),
    state => state.session.enabledFeatures,
    state => getOACLabelIds(state),
    state => getFlowFiltersForSelectedFlow(state),
    state => getFlowCasesForSelectedFlow(state),
    state => state.fieldsByCompany.enabledFieldLabels,
    state => getAncestorsFields(state),
    discoveriesToErrorsById
);

export const allDiscoveriesHaveResultsTable = createSelector(
    state => state.selected.flow,
    state => getFlowDiscoveriesForSelectedFlow(state),
    (selectedFlow: number, flowDiscoveries: Array<FlowItem>): boolean => {
        if (flowDiscoveries.length == 0) {
            return false;
        }

        return flowDiscoveries.filter(x => x.HasResultTable == false).length == 0;
    }
);

//////////////////// HELPERS //////////////////////////////
export const validateFlowDiscovery = (
    flowDiscovery: FlowItem,
    appSettings: Array<Object>,
    flowRelations: Array<FlowRelation>,
    enabledFeatures: Array<string>,
    oacLabelIds: Array<number>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    enabledFieldLabels: { number: Array<number> },
    ancestorsFields: Array<FlowItemFieldsUsed>
): Array<string> => {
    const errors = [];
    const audienceDiscoveryName = "Audience Insights";

    // Settings
    if (flowDiscovery.FlowItemName == "New Item") {
        errors.push(`${audienceDiscoveryName} needs to have a custom name.`);
    }
    const discoveryDB = appSettings ? appSettings.find(x => x.Name == "AudienceInsightsDatabase") : null;
    if (!discoveryDB || (discoveryDB && discoveryDB.SettingValue == "")) {
        errors.push("No Company AudienceInsightsDatabase setting has been assigned.");
    }
    const discoveryKey = appSettings ? appSettings.find(x => x.Name == "AudienceInsightsKey") : null;
    if (!discoveryKey || (discoveryKey && discoveryKey.SettingValue == "")) {
        errors.push("No Company AudienceInsightsKey setting has been assigned.");
    }

    // Relations
    const discoveryRelations = flowRelations.filter(
        z => z.ParentFlowItemId == 0 && z.ChildFlowItemId == flowDiscovery.FlowItemId
    );
    const allRelations = flowRelations.filter(z => z.ChildFlowItemId == flowDiscovery.FlowItemId);
    if (discoveryRelations.length > 0 || allRelations.length == 0) {
        errors.push(`${audienceDiscoveryName} items must have a parent item assigned.`);
    }

    const ancestorFieldIds = getAncestorFieldIdsForFlowItemId(
        flowDiscovery.FlowItemId,
        flowRelations,
        flowFilters,
        flowCases
    );

    const ancestorHasOACFields = fieldsHaveNeedsApprovalLabels(
        ancestorFieldIds,
        oacLabelIds,
        enabledFieldLabels,
        false
    );

    if (ancestorHasOACFields) {
        errors.push("OAC data cannot be pulled into Insights. Please update your audience selections.");
    }

    // Data
    const thirdPartyAncestorFields = ancestorsFields.filter(
        z => (z.FieldClassification == FieldClassification.ThirdParty || z.FieldClassification == FieldClassification.PrivateThirdParty) && ancestorFieldIds.includes(z.FieldId)
    );

    if (thirdPartyAncestorFields.length > 0) {
        errors.push("Third party data is not allowed to be connected to Insights.");
    }

    return errors;
};
