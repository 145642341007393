import makeReducerFor from "./_genericDbReducer";
import update from "immutability-helper";
import ofilter from "../helpers/ofilter";
import clone from "../helpers/clone";
import { uniqArray } from "../helpers/typedHelpers";
import { FlowConstants } from "../helpers/constants";

import { createSelector } from "reselect";
import { combineReducers } from "redux";
import {
    getFlowItemChildrenIndex,
    getFlowRelationsForSelectedFlow,
    getFlowRelationsForAllFlows,
    getAncestorsFields,
} from "./flowRelations";
import {
    getFilterErrorsForSelectedFlow,
    getFilterErrorsForAllFlows,
    getFlowFiltersForSelectedFlow,
    getFlowFiltersArray,
} from "./flowFilters";
import { getFlowCannedReportErrorsForSelectedFlow, getFlowCannedReportErrorsForAllFlows } from "./flowCannedReports";
import {
    getExportErrorsForSelectedFlow,
    getExportErrorsForAllFlows,
    getFlowExportsHaveTapadTradeDesk,
} from "./flowExports";
import { getMultiExportErrorsForSelectedFlow, getMultiExportErrorsForAllFlows } from "./flowMultiExports";
import { getOutputErrorsForSelectedFlow, getOutputErrorsForAllFlows } from "./flowOutputs";
import { getExportReportErrorsForSelectedFlow, getExportReportErrorsForAllFlows } from "./flowExportReports";
import { getFromCloudErrorsForSelectedFlow, getFromCloudErrorsForAllFlows } from "./flowFromClouds";
import { getToCloudErrorsForSelectedFlow, getToCloudErrorsForAllFlows } from "./flowToClouds";
import { getSplitErrorsForSelectedFlow, getSplitErrorsForAllFlows } from "./flowSplits";
import {
    getCaseErrorsForSelectedFlow,
    getCaseErrorsForAllFlows,
    getFlowCasesForSelectedFlow,
    getFlowCasesArray,
} from "./flowCases";
import { getReportErrorsForSelectedFlow, getReportErrorsForAllFlows } from "./flowReports";
import { getModelErrorsForSelectedFlow, getModelErrorsForAllFlows } from "./flowModels";
import { getSVDedupeErrorsForSelectedFlow, getSVDedupeErrorsForAllFlows } from "./flowSVDedupes";
import { getMergeErrorsForSelectedFlow, getMergeErrorsForAllFlows } from "./flowMerges";
import { getOfferMergeErrorsForSelectedFlow, getOfferMergeErrorsForAllFlows } from "./flowOfferMerges";
import { getDataLoadErrorsForSelectedFlow, getDataLoadErrorsForAllFlows } from "./flowDataLoads";
import { getSingleViewErrorsForSelectedFlow, getSingleViewErrorsForAllFlows } from "./flowSingleViews";
import { getOffloadErrorsForSelectedFlow, getOffloadErrorsForAllFlows } from "./flowOffloads";
import { getDiscoveryErrorsForSelectedFlow, getDiscoveryErrorsForAllFlows } from "./flowDiscovery";
import { getExportTemplateErrorsForSelectedFlow, getExportTemplateErrorsForAllFlows } from "./flowExportTemplateFields";
import {
    getExportPinterestTemplateErrorsForSelectedFlow,
    getExportPinterestTemplateErrorsForAllFlows,
} from "./flowExportPinterestTemplateFields";
import {
    getExportTikTokTemplateErrorsForSelectedFlow,
    getExportTikTokTemplateErrorsForAllFlows,
} from "./flowExportTikTokTemplateFields";
import {
    getExportTradeDeskTemplateErrorsForSelectedFlow,
    getExportTradeDeskTemplateErrorsForAllFlows,
} from "./flowExportTradeDeskTemplateFields";
import {
    getExportTaxonomyFileFieldErrorsForSelectedFlow,
    getExportTaxonomyFileFieldErrorsForAllFlows,
} from "./taxonomyLayout";
import {
    getFlowExternalServiceErrorsForSelectedFlow,
    getFlowExternalServiceErrorsForAllFlows,
} from "./flowExternalServices";
import { getFlowExpressionErrorsForSelectedFlow, getFlowExpressionErrorsForAllFlows } from "./flowExpressions";

import type {
    Flow,
    FlowItem,
    FlowErrorsByItemId,
    FlowAndItemPermissions,
    FlowPermissions,
    FlowRelation,
    FlowSortByFieldsByItemId,
    FlowFilter,
    FlowCase,
    FlowItemFieldsUsed,
} from "../types/flowTypes";
import type { IndexType, SortByFieldNoNull } from "../types/types";
import { FieldClassification } from "../enums/FieldClassifications";
import { getScriptErrorsForAllFlows, getScriptErrorsForSelectedFlow } from "./flowScripts";
import { getScriptDBUIErrorsForAllFlows, getScriptDBUIErrorsForSelectedFlow } from "./flowScriptsDBUI";
import { getNeedsApprovalLabelIds, getOACLabelIds } from "./fieldLabels";
import { getAncestorFieldIdsForFlowItemId, getAncestorFilterFieldIdsForFlowItemId } from "../helpers/flowItems";
import { fieldsHaveNeedsApprovalLabels } from "../helpers/needsApproval";
import {
    getExportFreewheelDriverFileFieldErrorsForAllFlows,
    getExportFreewheelDriverFileFieldErrorsForSelectedFlow,
} from "./freewheel";
import {
    getExportXandrDriverFieldsErrorsForAllFlows,
    getExportXandrDriverFieldsErrorsForSelectedFlow,
} from "./flowExportXandrDriverFields";

const myGenericReducer = makeReducerFor("FLOW_ITEM", "FlowItemId");

// We need a custom reducer to process the FLOW_ID_CHANGE inside FlowItems.
// Any FlowItems that refer to the old FlowId need to be updated as well.
const flowItemsById = (state = {}, action) => {
    switch (action.type) {
        case "FLOW_ID_CHANGE": {
            const updates = {};
            let madeAnUpdate = false;
            for (const idChange of action.idChanges) {
                if (idChange == null || !Array.isArray(idChange) || idChange.length < 2) {
                    console.warn(`$FLOW_ID_CHANGE: idChange is of incorrect form`); // eslint-disable-line no-console
                    continue;
                }
                const oldKey = idChange[0];
                const newKey = idChange[1];

                // Look for any flowItems belonging to the oldFlowId
                const stateKeysToUpdate = Object.keys(ofilter(v => v.FlowId == oldKey, state));
                if (stateKeysToUpdate.length == 0) {
                    continue;
                }

                for (const key of stateKeysToUpdate) {
                    madeAnUpdate = true;
                    updates[key] = {
                        FlowId: { $set: newKey },
                    };
                }
            }
            return madeAnUpdate ? update(state, updates) : state;
        }
        default:
            return state;
    }
};
const myCustomReducer = combineReducers({
    byId: flowItemsById,
});

const myReducer = (state = {}, action) => myCustomReducer(myGenericReducer(state, action), action);
export default myReducer;

type FlowItemsById = {| [number]: FlowItem |};

/////////// SELECTORS ///////////////////

// selector: Get Array of all FlowItems in redux.
export const getFlowItemsArray = createSelector(
    state => state.flowItems.byId,
    (flowItemsById: FlowItemsById): Array<FlowItem> => {
        const r: Array<FlowItem> = Object.values(flowItemsById);
        return r;
    }
);

// selector: Get Array of all FlowItems belonging to the currently selected flow.
export const getFlowItemsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsArray(state),
    (selectedFlow: number, flowItems: Array<FlowItem>): Array<FlowItem> =>
        flowItems.filter(x => x.FlowId == selectedFlow)
);

export type FlowItemTypeLookup = { [number /* FlowItemId */]: string /* FlowItemType */ };
export const getFlowItemTypeLookupForSelectedFlow = createSelector(
    state => getFlowItemsArray(state),
    (flowItems: Array<FlowItem>): FlowItemTypeLookup =>
        flowItems.reduce((map, obj) => {
            map[obj.FlowItemId] = obj.FlowItemType;
            return map;
        }, {})
);

// selector: Get Array of all FlowItem.FlowItemIds (number) belonging to the currently selected flow.
// This shouldn't be deep equals selector, rather anything depending on it might want a smarter shouldcomponentupdate
export const getFlowItemIdsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>) => flowItems.map(x => x.FlowItemId)
);

// selector: Get the number of how many FlowItems the selected flow has.
export const getNumFlowItemsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): number => flowItems.length
);

// Is any flow item belonging to the selected flow running?
export const getIsAnyFlowItemRunning = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>) => flowItems.filter(x => x.IsRunning).length > 0
);

// insights doesn't have propperties, its just a different type so returning the list of flow items
export const getFlowInsightsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>) => flowItems.filter(x => x.FlowItemType == "insights")
);

export const getFlowInsightsArray = createSelector(
    state => getFlowItemsArray(state),
    (flowItems: Array<FlowItem>) => flowItems.filter(x => x.FlowItemType == "insights")
);

export const getSelectedFlowItem = createSelector(
    state => state.selected.flowItem,
    state => state.flowItems.byId,
    (flowItemId: number, flowItemsById: FlowItemsById) => flowItemsById[flowItemId]
);

// selectors to get insight errors
export const getInsightsErrorsForSelectedFlow = createSelector(
    state => getFlowInsightsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    state => getNeedsApprovalLabelIds(state),
    state => getOACLabelIds(state),
    state => getFlowFiltersForSelectedFlow(state),
    state => getFlowCasesForSelectedFlow(state),
    state => state.fieldsByCompany.enabledFieldLabels,
    state => state.session.isInternal,
    state => getAncestorsFields(state),
    (
        flowInsights: Array<FlowItem>,
        flowRelations: Array<FlowRelation>,
        needApprovalLabelIds: Array<number>,
        oacLabelIds: Array<number>,
        flowFilters: Array<FlowFilter>,
        flowCases: Array<FlowCase>,
        enabledFieldLabels: { number: Array<number> },
        isInternal: boolean,
        ancestorsFields: Array<FlowItemFieldsUsed>
    ): FlowErrorsByItemId => {
        const errorsById = {};
        for (const insight of flowInsights) {
            errorsById[insight.FlowItemId] = validateFlowInsight(
                insight,
                flowRelations,
                needApprovalLabelIds,
                oacLabelIds,
                flowFilters,
                flowCases,
                enabledFieldLabels,
                isInternal,
                ancestorsFields
            );
        }
        return errorsById;
    }
);

export const getInsightsErrorsForAllFlows = createSelector(
    state => getFlowInsightsArray(state),
    state => getFlowRelationsForAllFlows(state),
    state => getNeedsApprovalLabelIds(state),
    state => getOACLabelIds(state),
    state => getFlowFiltersArray(state),
    state => getFlowCasesArray(state),
    state => state.fieldsByCompany.enabledFieldLabels,
    state => state.session.isInternal,
    state => getAncestorsFields(state),
    (
        flowInsights: Array<FlowItem>,
        flowRelations: Array<FlowRelation>,
        needApprovalLabelIds: Array<number>,
        oacLabelIds: Array<number>,
        flowFilters: Array<FlowFilter>,
        flowCases: Array<FlowCase>,
        enabledFieldLabels: { number: Array<number> },
        isInternal: boolean,
        ancestorsFields: Array<FlowItemFieldsUsed>
    ): FlowErrorsByItemId => {
        const errorsById = {};
        for (const insight of flowInsights) {
            errorsById[insight.FlowItemId] = validateFlowInsight(
                insight,
                flowRelations,
                needApprovalLabelIds,
                oacLabelIds,
                flowFilters,
                flowCases,
                enabledFieldLabels,
                isInternal,
                ancestorsFields
            );
        }
        return errorsById;
    }
);

export const getFlowInsightsNeedApprovals = () =>
    createSelector(
        (_, props) => props.id,
        //state => getFlowItemsForSelectedFlow(state),
        state => getFlowInsightsForSelectedFlow(state),
        state => getFlowRelationsForSelectedFlow(state),
        state => getNeedsApprovalLabelIds(state),
        state => getFlowFiltersForSelectedFlow(state),
        state => getFlowCasesForSelectedFlow(state),
        state => state.fieldsByCompany.enabledFieldLabels,
        state => state.session.isInternal,
        (
            flowItemId: number,
            // flowItems: Array<FlowItem>,
            flowInsights: Array<FlowItem>,
            flowRelations: Array<FlowRelation>,
            needsApprovalLabelIds: Array<number>,
            flowFilters: Array<FlowFilter>,
            flowCases: Array<FlowCase>,
            enabledFieldLabels: { number: Array<number> },
            isInternal: boolean
        ): boolean => {
            const item = flowInsights.find(x => x.FlowItemId == flowItemId);
            if (!item || item.FlowItemType != "insights") {
                return false;
            }

            const ancestorFieldIds = getAncestorFilterFieldIdsForFlowItemId(flowItemId, flowRelations, flowFilters);

            const ancestorHasNeedsApprovalFields = fieldsHaveNeedsApprovalLabels(
                ancestorFieldIds,
                needsApprovalLabelIds,
                enabledFieldLabels,
                isInternal
            );
            return ancestorHasNeedsApprovalFields;
        }
    );

// validates flow insights, needs to have a parent node and parent's can't have needs_approval labeled fields
const validateFlowInsight = (
    insight: FlowItem,
    flowRelations: Array<FlowRelation>,
    needsApprovalLabelIds: Array<number>,
    oacLabelIds: Array<number>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    enabledFieldLabels: { number: Array<number> },
    isInternal: boolean,
    ancestorsFields: Array<FlowItemFieldsUsed>
) => {
    const errors = [];
    const parents = flowRelations.filter(x => x.ChildFlowItemId == insight.FlowItemId && x.ParentFlowItemId != 0);
    if (parents.length <= 0) {
        errors.push("Basic Insights needs a filter parent node.");
    }

    const ancestorFieldIds = getAncestorFieldIdsForFlowItemId(
        insight.FlowItemId,
        flowRelations,
        flowFilters,
        flowCases
    );

    const ancestorHasNeedsApprovalFields = fieldsHaveNeedsApprovalLabels(
        ancestorFieldIds,
        needsApprovalLabelIds,
        enabledFieldLabels,
        isInternal
    );

    // false for isinternal to always prevent oac from moving forward.
    const ancestorHasOACFields = fieldsHaveNeedsApprovalLabels(
        ancestorFieldIds,
        oacLabelIds,
        enabledFieldLabels,
        false
    );

    if (ancestorHasOACFields) {
        errors.push("OAC data cannot be pulled into Insights. Please update your audience selections.");
    }

    if (ancestorHasNeedsApprovalFields) {
        errors.push("Basic Insights parents contain fields that require approval.");
    }

    const thirdPartyAncestorFields = ancestorsFields.filter(
        z => (z.FieldClassification == FieldClassification.ThirdParty || z.FieldClassification == FieldClassification.PrivateThirdParty) && ancestorFieldIds.includes(z.FieldId)
    );

    if (thirdPartyAncestorFields.length > 0) {
        errors.push("Third party data is not allowed to be connected to Insights.");
    }

    return errors;
};

// selector: Get a hash of all ItemErrors for the currently selected flow.
//
// { [flowItemId: number]: Array<string> };
// Items without errors will be an empty array.  The errors may come from
// the flowitem itself or its subtype.
// (Example: If a flowitem has a ItemType="split", the associated split may have an error).

// All errors
// At maximum number of arguments in a reselect - maybe not?
const consolidateAllErrors = (
    flowItems: Array<FlowItem>,
    flowFilterErrors: FlowErrorsByItemId,
    flowSplitErrors: FlowErrorsByItemId,
    flowCaseErrors: FlowErrorsByItemId,
    flowMergeErrors: FlowErrorsByItemId,
    flowExportErrors: FlowErrorsByItemId,
    flowExportReportErrors: FlowErrorsByItemId,
    flowCannedReportErrors: FlowErrorsByItemId,
    flowFromCloudErrors: FlowErrorsByItemId,
    flowToCloudErrors: FlowErrorsByItemId,
    flowSVDedupeErrors: FlowErrorsByItemId,
    flowReportErrors: FlowErrorsByItemId,
    flowModelErrors: FlowErrorsByItemId,
    flowHouseExpandErrors: FlowErrorsByItemId,
    flowOfferErrors: FlowErrorsByItemId,
    flowDataLoadErrors: FlowErrorsByItemId,
    flowOutputErrors: FlowErrorsByItemId,
    flowSingleViewErrors: Array<FlowErrorsByItemId>,
    flowOffloadErrors: FlowErrorsByItemId,
    flowScriptErrors: FlowErrorsByItemId,
    flowScriptDBUIErrors: FlowErrorsByItemId,
    flowInsightsErrors: FlowErrorsByItemId,
    flowDiscoveryErrors: FlowErrorsByItemId,
    flowExportTemplateFieldErrors: FlowErrorsByItemId,
    flowExportPinterestFieldErrors: FlowErrorsByItemId,
    flowExportTikTokFieldErrors: FlowErrorsByItemId,
    flowExportTradeDeskTemplateFieldErrors: FlowErrorsByItemId,
    flowExportTaxonomyTemaplateFieldErrors: FlowErrorsByItemId,
    flowExportFreewheelDriverFileFieldErrors: FlowErrorsByItemId,
    flowExportXandrDriverFieldErrors: FlowErrorsByItemId,
    flowExternalServiceErrors: FlowErrorsByItemId,
    flowExpressionErrors: FlowErrorsByItemId,
    flowExportMultiExportFieldErrors: FlowErrorsByItemId
): FlowErrorsByItemId => {
    // Compute Item Errors
    let errorsById = {};
    for (const flowItem of flowItems) {
        const itemId = flowItem.FlowItemId;
        // Merge Item Errors, Filter Errors, and Split Errors into one
        const itemErrors = validateFlowItem(flowItem)
            .concat(flowFilterErrors[itemId] || [])
            .concat(flowSplitErrors[itemId] || [])
            .concat(flowCaseErrors[itemId] || [])
            .concat(flowMergeErrors[itemId] || [])
            .concat(flowExportErrors[itemId] || [])
            .concat(flowModelErrors[itemId] || [])
            .concat(flowExportReportErrors[itemId] || [])
            .concat(flowCannedReportErrors[itemId] || [])
            .concat(flowFromCloudErrors[itemId] || [])
            .concat(flowToCloudErrors[itemId] || [])
            .concat(flowSVDedupeErrors[itemId] || [])
            .concat(flowReportErrors[itemId] || [])
            .concat(flowHouseExpandErrors[itemId] || [])
            .concat(flowOfferErrors[itemId] || [])
            .concat(flowDataLoadErrors[itemId] || [])
            .concat(flowOutputErrors[itemId] || [])
            .concat(flowSingleViewErrors[itemId] || [])
            .concat(flowOffloadErrors[itemId] || [])
            .concat(flowScriptErrors[itemId] || [])
            .concat(flowScriptDBUIErrors[itemId] || [])
            .concat(flowInsightsErrors[itemId] || [])
            .concat(flowDiscoveryErrors[itemId] || [])
            .concat(flowExportTemplateFieldErrors[itemId] || [])
            .concat(flowExportPinterestFieldErrors[itemId] || [])
            .concat(flowExportTikTokFieldErrors[itemId] || [])
            .concat(flowExportTradeDeskTemplateFieldErrors[itemId] || [])
            .concat(flowExportTaxonomyTemaplateFieldErrors[itemId] || [])
            .concat(flowExportFreewheelDriverFileFieldErrors[itemId] || [])
            .concat(flowExportXandrDriverFieldErrors[itemId] || [])
            .concat(flowExternalServiceErrors[itemId] || [])
            .concat(flowExpressionErrors[itemId] || [])
            .concat(flowExportMultiExportFieldErrors[itemId] || []);
        errorsById[flowItem.FlowItemId] = itemErrors;
    }
    errorsById = ofilter(v => v.length > 0, errorsById);
    return errorsById;
};

// Errors on IsActive items only
// Hit maximum number of arguments in a reselect so have to duplicate - might not be max?
const consolidateAllErrorsActive = (
    flowItems: Array<FlowItem>,
    flowFilterErrors: FlowErrorsByItemId,
    flowSplitErrors: FlowErrorsByItemId,
    flowCaseErrors: FlowErrorsByItemId,
    flowMergeErrors: FlowErrorsByItemId,
    flowExportErrors: FlowErrorsByItemId,
    flowExportReportErrors: FlowErrorsByItemId,
    flowCannedReportErrors: FlowErrorsByItemId,
    flowFromCloudErrors: FlowErrorsByItemId,
    flowToCloudErrors: FlowErrorsByItemId,
    flowSVDedupeErrors: FlowErrorsByItemId,
    flowReportErrors: FlowErrorsByItemId,
    flowModelErrors: FlowErrorsByItemId,
    flowHouseExpandErrors: FlowErrorsByItemId,
    flowOfferErrors: FlowErrorsByItemId,
    flowDataLoadErrors: Array<FlowErrorsByItemId>,
    flowOutputErrors: Array<FlowErrorsByItemId>,
    flowSingleViewErrors: Array<FlowErrorsByItemId>,
    flowOffloadErrors: FlowErrorsByItemId,
    flowScriptErrors: FlowErrorsByItemId,
    flowScriptDBUIErrors: FlowErrorsByItemId,
    flowInsightsErrors: FlowErrorsByItemId,
    flowDiscoveryErrors: FlowErrorsByItemId,
    flowExportTemplateFieldErrors: FlowErrorsByItemId,
    flowExportPinterestFieldErrors: FlowErrorsByItemId,
    flowExportTikTokFieldErrors: FlowErrorsByItemId,
    flowExportTradeDeskTemplateFieldErrors: FlowErrorsByItemId,
    flowExportTaxonomyTemaplateFieldErrors: FlowErrorsByItemId,
    flowExportFreewheelDriverFileFieldErrors: FlowErrorsByItemId,
    flowExportXandrDriverFieldErrors: FlowErrorsByItemId,
    flowExternalServiceErrors: FlowErrorsByItemId,
    flowExpressionErrors: FlowErrorsByItemId,
    flowExportMultiExportFieldErrors: FlowErrorsByItemId
): FlowErrorsByItemId => {
    // Compute Item Errors
    let errorsById = {};
    for (const flowItem of flowItems) {
        // Skip disabled nodes
        if (flowItem.IsActive == false) {
            continue;
        }
        const itemId = flowItem.FlowItemId;
        // Merge Item Errors, Filter Errors, and Split Errors into one
        const itemErrors = validateFlowItem(flowItem)
            .concat(flowFilterErrors[itemId] || [])
            .concat(flowSplitErrors[itemId] || [])
            .concat(flowCaseErrors[itemId] || [])
            .concat(flowMergeErrors[itemId] || [])
            .concat(flowExportErrors[itemId] || [])
            .concat(flowModelErrors[itemId] || [])
            .concat(flowExportReportErrors[itemId] || [])
            .concat(flowCannedReportErrors[itemId] || [])
            .concat(flowFromCloudErrors[itemId] || [])
            .concat(flowToCloudErrors[itemId] || [])
            .concat(flowSVDedupeErrors[itemId] || [])
            .concat(flowReportErrors[itemId] || [])
            .concat(flowHouseExpandErrors[itemId] || [])
            .concat(flowOfferErrors[itemId] || [])
            .concat(flowDataLoadErrors[itemId] || [])
            .concat(flowOutputErrors[itemId] || [])
            .concat(flowSingleViewErrors[itemId] || [])
            .concat(flowOffloadErrors[itemId] || [])
            .concat(flowScriptErrors[itemId] || [])
            .concat(flowScriptDBUIErrors[itemId] || [])
            .concat(flowInsightsErrors[itemId] || [])
            .concat(flowDiscoveryErrors[itemId] || [])
            .concat(flowExportTemplateFieldErrors[itemId] || [])
            .concat(flowExportPinterestFieldErrors[itemId] || [])
            .concat(flowExportTikTokFieldErrors[itemId] || [])
            .concat(flowExportTradeDeskTemplateFieldErrors[itemId] || [])
            .concat(flowExportTaxonomyTemaplateFieldErrors[itemId] || [])
            .concat(flowExportFreewheelDriverFileFieldErrors[itemId] || [])
            .concat(flowExportXandrDriverFieldErrors[itemId] || [])
            .concat(flowExternalServiceErrors[itemId] || [])
            .concat(flowExpressionErrors[itemId] || [])
            .concat(flowExportMultiExportFieldErrors[itemId] || []);
        errorsById[flowItem.FlowItemId] = itemErrors;
    }
    errorsById = ofilter(v => v.length > 0, errorsById);
    return errorsById;
};

// All

export const getItemErrorsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFilterErrorsForSelectedFlow(state),
    state => getSplitErrorsForSelectedFlow(state),
    state => getCaseErrorsForSelectedFlow(state),
    state => getMergeErrorsForSelectedFlow(state),
    state => getExportErrorsForSelectedFlow(state),
    state => getExportReportErrorsForSelectedFlow(state),
    state => getFlowCannedReportErrorsForSelectedFlow(state),
    state => getFromCloudErrorsForSelectedFlow(state),
    state => getToCloudErrorsForSelectedFlow(state),
    state => getSVDedupeErrorsForSelectedFlow(state),
    state => getReportErrorsForSelectedFlow(state),
    state => getModelErrorsForSelectedFlow(state),
    state => getHouseExpandErrorsForSelectedFlow(state),
    state => getOfferMergeErrorsForSelectedFlow(state),
    state => getDataLoadErrorsForSelectedFlow(state),
    state => getOutputErrorsForSelectedFlow(state),
    state => getSingleViewErrorsForSelectedFlow(state),
    state => getOffloadErrorsForSelectedFlow(state),
    state => getScriptErrorsForSelectedFlow(state),
    state => getScriptDBUIErrorsForSelectedFlow(state),
    state => getInsightsErrorsForSelectedFlow(state),
    state => getDiscoveryErrorsForSelectedFlow(state),
    state => getExportTemplateErrorsForSelectedFlow(state),
    state => getExportPinterestTemplateErrorsForSelectedFlow(state),
    state => getExportTikTokTemplateErrorsForSelectedFlow(state),
    state => getExportTradeDeskTemplateErrorsForSelectedFlow(state),
    state => getExportTaxonomyFileFieldErrorsForSelectedFlow(state),
    state => getExportFreewheelDriverFileFieldErrorsForSelectedFlow(state),
    state => getExportXandrDriverFieldsErrorsForSelectedFlow(state),
    state => getFlowExternalServiceErrorsForSelectedFlow(state),
    state => getFlowExpressionErrorsForSelectedFlow(state),
    state => getMultiExportErrorsForSelectedFlow(state),
    consolidateAllErrors
);

// Only IsActive

export const getActiveItemErrorsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFilterErrorsForSelectedFlow(state),
    state => getSplitErrorsForSelectedFlow(state),
    state => getCaseErrorsForSelectedFlow(state),
    state => getMergeErrorsForSelectedFlow(state),
    state => getExportErrorsForSelectedFlow(state),
    state => getExportReportErrorsForSelectedFlow(state),
    state => getFlowCannedReportErrorsForSelectedFlow(state),
    state => getFromCloudErrorsForSelectedFlow(state),
    state => getToCloudErrorsForSelectedFlow(state),
    state => getSVDedupeErrorsForSelectedFlow(state),
    state => getReportErrorsForSelectedFlow(state),
    state => getModelErrorsForSelectedFlow(state),
    state => getHouseExpandErrorsForSelectedFlow(state),
    state => getOfferMergeErrorsForSelectedFlow(state),
    state => getDataLoadErrorsForSelectedFlow(state),
    state => getOutputErrorsForSelectedFlow(state),
    state => getSingleViewErrorsForSelectedFlow(state),
    state => getOffloadErrorsForSelectedFlow(state),
    state => getScriptErrorsForSelectedFlow(state),
    state => getScriptDBUIErrorsForSelectedFlow(state),
    state => getInsightsErrorsForSelectedFlow(state),
    state => getDiscoveryErrorsForSelectedFlow(state),
    state => getExportTemplateErrorsForSelectedFlow(state),
    state => getExportPinterestTemplateErrorsForSelectedFlow(state),
    state => getExportTikTokTemplateErrorsForSelectedFlow(state),
    state => getExportTradeDeskTemplateErrorsForSelectedFlow(state),
    state => getExportTaxonomyFileFieldErrorsForSelectedFlow(state),
    state => getExportFreewheelDriverFileFieldErrorsForSelectedFlow(state),
    state => getExportXandrDriverFieldsErrorsForSelectedFlow(state),
    state => getFlowExternalServiceErrorsForSelectedFlow(state),
    state => getFlowExpressionErrorsForSelectedFlow(state),
    state => getMultiExportErrorsForSelectedFlow(state),
    consolidateAllErrorsActive
);

// All

export const getItemErrorsForAllFlows = createSelector(
    state => getFlowItemsArray(state),
    state => getFilterErrorsForAllFlows(state),
    state => getSplitErrorsForAllFlows(state),
    state => getCaseErrorsForAllFlows(state),
    state => getMergeErrorsForAllFlows(state),
    state => getExportErrorsForAllFlows(state),
    state => getExportReportErrorsForAllFlows(state),
    state => getFlowCannedReportErrorsForAllFlows(state),
    state => getFromCloudErrorsForAllFlows(state),
    state => getToCloudErrorsForAllFlows(state),
    state => getSVDedupeErrorsForAllFlows(state),
    state => getReportErrorsForAllFlows(state),
    state => getModelErrorsForAllFlows(state),
    state => getHouseExpandErrorsForAllFlows(state),
    state => getOfferMergeErrorsForAllFlows(state),
    state => getDataLoadErrorsForAllFlows(state),
    state => getOutputErrorsForAllFlows(state),
    state => getSingleViewErrorsForAllFlows(state),
    state => getOffloadErrorsForAllFlows(state),
    state => getScriptErrorsForAllFlows(state),
    state => getScriptDBUIErrorsForAllFlows(state),
    state => getInsightsErrorsForAllFlows(state),
    state => getDiscoveryErrorsForAllFlows(state),
    state => getExportTemplateErrorsForAllFlows(state),
    state => getExportPinterestTemplateErrorsForAllFlows(state),
    state => getExportTikTokTemplateErrorsForAllFlows(state),
    state => getExportTradeDeskTemplateErrorsForAllFlows(state),
    state => getExportTaxonomyFileFieldErrorsForAllFlows(state),
    state => getExportFreewheelDriverFileFieldErrorsForAllFlows(state),
    state => getExportXandrDriverFieldsErrorsForAllFlows(state),
    state => getFlowExternalServiceErrorsForAllFlows(state),
    state => getFlowExpressionErrorsForAllFlows(state),
    state => getMultiExportErrorsForAllFlows(state),
    consolidateAllErrors
);

// Only IsActive

export const getActiveItemErrorsForAllFlows = createSelector(
    state => getFlowItemsArray(state),
    state => getFilterErrorsForAllFlows(state),
    state => getSplitErrorsForAllFlows(state),
    state => getCaseErrorsForAllFlows(state),
    state => getMergeErrorsForAllFlows(state),
    state => getExportErrorsForAllFlows(state),
    state => getExportReportErrorsForAllFlows(state),
    state => getFlowCannedReportErrorsForAllFlows(state),
    state => getFromCloudErrorsForAllFlows(state),
    state => getToCloudErrorsForAllFlows(state),
    state => getSVDedupeErrorsForAllFlows(state),
    state => getReportErrorsForAllFlows(state),
    state => getModelErrorsForAllFlows(state),
    state => getHouseExpandErrorsForAllFlows(state),
    state => getOfferMergeErrorsForAllFlows(state),
    state => getFlowRelationsForSelectedFlow(state),
    state => getDataLoadErrorsForAllFlows(state),
    state => getOutputErrorsForAllFlows(state),
    state => getSingleViewErrorsForAllFlows(state),
    state => getOffloadErrorsForAllFlows(state),
    state => getScriptErrorsForAllFlows(state),
    state => getScriptDBUIErrorsForAllFlows(state),
    state => getInsightsErrorsForAllFlows(state),
    state => getDiscoveryErrorsForAllFlows(state),
    state => getExportTemplateErrorsForAllFlows(state),
    state => getExportPinterestTemplateErrorsForAllFlows(state),
    state => getExportTikTokTemplateErrorsForAllFlows(state),
    state => getExportTradeDeskTemplateErrorsForAllFlows(state),
    state => getExportTaxonomyFileFieldErrorsForAllFlows(state),
    state => getExportFreewheelDriverFileFieldErrorsForAllFlows(state),
    state => getExportXandrDriverFieldsErrorsForAllFlows(state),
    state => getFlowExternalServiceErrorsForAllFlows(state),
    state => getFlowExpressionErrorsForAllFlows(state),
    state => getMultiExportErrorsForAllFlows(state),
    consolidateAllErrorsActive
);

export const getIsAnyErrorsInSelectedFlow = createSelector(
    state => getActiveItemErrorsForSelectedFlow(state),
    state => getFlowItemsForSelectedFlow(state),
    (itemErrors: FlowErrorsByItemId, allItems: Array<FlowItem>): boolean =>
        isFlowAnyErrorsWithExceptions(itemErrors, allItems)
);

// selector factory:  Get ItemErrors for one specified FlowItem that belongs to the
// selected Flow.  Assumes the FlowItemId is in prop "id".
// Returns Array<string>, which is empty array if no errors.
export const makeGetItemErrorsForId = () =>
    createSelector(
        (_, props) => props.id,
        state => getItemErrorsForSelectedFlow(state),
        (id: number, itemErrors: FlowErrorsByItemId): Array<string> => itemErrors[id] || []
    );
export const makeGetItemSortByFieldsForId = () =>
    createSelector(
        (_, props) => props.flowItemId,
        state => getSortFieldsForSelectedFlow(state),
        (flowItemId: number, itemSortFields: FlowSortByFieldsByItemId): Array<SortByFieldNoNull> =>
            itemSortFields[flowItemId] || []
    );

export const getFlowPermissionsFromProps = () =>
    createSelector(
        (_, props) => props.flowId,
        state => state.flows.byId,
        state => getFlowItemsArray(state),
        state => getFlowsWithErrors(state),
        state => state.session.roles,
        state => state.session.userId,
        state => getFlowExportsHaveTapadTradeDesk(state),
        state => state.session.enabledFeatures,
        (
            flowId: number,
            flowsById: { [number]: Flow },
            flowItemsArray: Array<FlowItem>,
            flowsWithErrors: Array<number>,
            roles: Array<string>,
            userId: number,
            hasTapadTradeDesk: boolean,
            enabledFeatures: Array<string>
        ): FlowPermissions =>
            getFlowPermissions(
                flowsById[flowId],
                flowItemsArray,
                flowsWithErrors.includes(flowId),
                roles,
                userId,
                hasTapadTradeDesk,
                enabledFeatures
            )
    );

export const getSortFieldsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): FlowSortByFieldsByItemId => {
        let sortFieldsById = {};
        for (const flowItem of flowItems) {
            // Get JSON and return Array
            const sortFields = flowItem.SortByFieldsJSON ? JSON.parse(flowItem.SortByFieldsJSON) : [];
            sortFieldsById[flowItem.FlowItemId] = sortFields;
        }

        sortFieldsById = ofilter(v => v && v.length > 0, sortFieldsById);
        return sortFieldsById;
    }
);

// Get permissions for the currently selected flow.
export const getSelectedFlowPermissions = createSelector(
    state => state.flows.byId[state.selected.flow],
    state => getFlowItemsForSelectedFlow(state),
    state => getIsAnyErrorsInSelectedFlow(state),
    state => state.session.roles,
    state => state.session.userId,
    state => getFlowExportsHaveTapadTradeDesk(state),
    state => state.session.enabledFeatures,
    (
        flow: ?Flow,
        allItems: Array<FlowItem>,
        isError: boolean,
        roles: Array<string>,
        userId: number,
        hasTapadTradeDesk: boolean,
        enabledFeatures: Array<string>
    ): FlowPermissions => getFlowPermissions(flow, allItems, isError, roles, userId, hasTapadTradeDesk, enabledFeatures)
);

// A callable getSelectedFlowPermissions
export const makeGetSelectedFlowPermissions = () =>
    createSelector(
        state => state.flows.byId[state.selected.flow],
        state => getFlowItemsForSelectedFlow(state),
        state => getIsAnyErrorsInSelectedFlow(state),
        state => state.session.roles,
        state => state.session.userId,
        state => getFlowExportsHaveTapadTradeDesk(state),
        state => state.session.enabledFeatures,
        (
            flow: ?Flow,
            allItems: Array<FlowItem>,
            isError: boolean,
            roles: Array<string>,
            userId: number,
            hasTapadTradeDesk: boolean,
            enabledFeatures: Array<string>
        ): FlowPermissions =>
            getFlowPermissions(flow, allItems, isError, roles, userId, hasTapadTradeDesk, enabledFeatures)
    );

// Get permissions for the currently selected flow + flowItem.
export const getSelectedFlowAndItemPermissions = createSelector(
    state => state.flowItems.byId[state.selected.flowItem],
    state => state.flows.byId[state.selected.flow],
    state => getFlowItemsForSelectedFlow(state),
    state => getItemErrorsForSelectedFlow(state),
    state => getFlowCannedReportErrorsForSelectedFlow(state),
    state => state.session.roles,
    state => state.session.userId,
    state => getFlowExportsHaveTapadTradeDesk(state),
    state => state.session.enabledFeatures,
    (
        flowItem: ?FlowItem,
        flow: ?Flow,
        allItems: Array<FlowItem>,
        itemErrors: FlowErrorsByItemId,
        flowCannedReportErrors: FlowErrorsByItemId,
        roles: Array<string>,
        userId: number,
        hasTapadTradeDesk: boolean,
        enabledFeatures: Array<string>
    ): FlowAndItemPermissions =>
        getFlowAndItemPermissions(
            flowItem,
            flow,
            allItems,
            itemErrors,
            flowCannedReportErrors,
            roles,
            userId,
            hasTapadTradeDesk,
            enabledFeatures
        )
);

// Get permissions for the currently selected flow, but choose the flowItem from props. (Requires a factory).
export const makeGetPermissionsItemFromProps = () =>
    createSelector(
        (_, props) => props.flowItemId,
        state => state.flowItems.byId,
        state => state.flows.byId[state.selected.flow],
        state => getFlowItemsForSelectedFlow(state),
        state => getItemErrorsForSelectedFlow(state),
        state => getFlowCannedReportErrorsForSelectedFlow(state),
        state => state.session.roles,
        state => state.session.userId,
        state => getFlowExportsHaveTapadTradeDesk(state),
        state => state.session.enabledFeatures,
        (
            flowItemId: number,
            flowItemsById: FlowItemsById,
            flow: ?Flow,
            allItems: Array<FlowItem>,
            itemErrors: FlowErrorsByItemId,
            flowCannedReportErrors: FlowErrorsByItemId,
            roles: Array<string>,
            userId: number,
            hasTapadTradeDesk: boolean,
            enabledFeatures: Array<string>
        ): FlowAndItemPermissions =>
            getFlowAndItemPermissions(
                flowItemsById[flowItemId],
                flow,
                allItems,
                itemErrors,
                flowCannedReportErrors,
                roles,
                userId,
                hasTapadTradeDesk,
                enabledFeatures
            )
    );

export const getFlowsWithErrors = createSelector(
    state => state.flowItems.byId,
    state => getActiveItemErrorsForAllFlows(state),
    (flowItems: {| [number]: FlowItem |}, errorsById: FlowErrorsByItemId): Array<number> => {
        errorsById = ofilter(v => v.length > 0, errorsById);
        const itemsWithErrors = Object.keys(errorsById)
            .map(x => parseInt(x, 10))
            .filter(x => flowItems[x] != null);
        const flowsWithErrors = itemsWithErrors
            .filter(x => flowItems[x].FlowItemType != "script")
            .map(x => flowItems[x].FlowId);
        return uniqArray(flowsWithErrors);
    }
);

//////////////// TREE RELATED //////////////

export type FlowItemTree = {
    // Not in DB
    parentId: ?number,
    flowItemId: number,
    level: number,
    children: Array<FlowItemTree>,
};

export const getFlowItemTreeForSelectedFlow = createSelector(
    state => state.flowItems.byId,
    state => state.selected.flow,
    state => getFlowItemChildrenIndex(state),
    (flowItemsById: FlowItemsById, selectedFlow: number, childrenIndex: IndexType): FlowItemTree => {
        const myChildrenIndex = clone(childrenIndex);
        if (myChildrenIndex[0] == null) {
            myChildrenIndex[0] = [];
        }
        myChildrenIndex[0] = myChildrenIndex[0].filter(
            id => flowItemsById[id] != null && flowItemsById[id].FlowId == selectedFlow
        );
        _createTreeMemo = {};
        const node: FlowItemTree = {
            parentId: null,
            flowItemId: 0,
            level: -1,
            children: _createTree(myChildrenIndex, 0, 0),
        };
        return node;
    }
);

let _createTreeMemo = {};

const _createTree = (childrenIndex: IndexType, parentId = 0, level = 0): Array<FlowItemTree> => {
    if (_createTreeMemo[parentId] != null) {
        // Memoize based on parentId.  Avoid duplicate calculations.
        // Assumption: childrenIndex is always the same.  Must reset memo when appropriate!
        return _createTreeMemo[parentId];
    }

    const childrenIds = childrenIndex[parentId] || [];
    const nodes = childrenIds.map(flowItemId => ({
        parentId,
        flowItemId,
        level,
        children: _createTree(childrenIndex, flowItemId, level + 1),
    }));
    _createTreeMemo[parentId] = sortNodes(nodes);
    return _createTreeMemo[parentId];
};

const sortNodes = x => x;

//////////////////// HELPERS //////////////////////////////

export const flowItemCanProcess = (flowItem: ?FlowItem) => {
    if (flowItem == null) {
        return false;
    }
    if (flowItem.FlowItemId <= 0) {
        return false;
    }
    return true;
};

export const isAnyParentIncomplete = (state: any, flowItemId: number): boolean => {
    if (state == null || flowItemId == null || state.flowItems == null) {
        return false;
    }

    const flowRelations = getFlowRelationsForSelectedFlow(state);
    const flowErrors = getItemErrorsForSelectedFlow(state);
    const parentRelations = flowRelations
        .filter(x => x.ChildFlowItemId == flowItemId && x.ParentFlowItemId != 0)
        .map(x => x.ParentFlowItemId);

    for (const parentId of parentRelations) {
        if (flowErrors[parentId] != null) {
            return true;
        } else if (isAnyParentIncomplete(state, parentId) == true) {
            return true;
        }
    }

    return false;
};

export const validateFlowItem = (flowItem: FlowItem): Array<string> => {
    const errors = [];
    if (flowItem.FlowItemName.trim() == "") {
        errors.push("Please enter a name.");
    }
    return errors;
};

export const variablesUrlForFlow = (flowId: number) => `/flows/${flowId.toString()}/variables`;

export const reportsUrlForFlow = (flowId: number) => `/flows/${flowId.toString()}/reports`;

export const editUrlForFlow = (flowId: number) => {
    if (flowId) {
        let flowUrl = `/flows/${flowId.toString()}/design`;

        if (window.location.href.includes("/ae/")) return flowUrl;
        else return `/ae${flowUrl}`;
    }
};

export const editUrlForFlowItem = (flowId: number, flowItemId: number) =>
    `/flows/${flowId.toString()}/item/${flowItemId.toString()}`;

// Find the position of a new node, if we have the parentId of the new node
export const calculateNewItemPosition = (state: any, parentId: ?number) => {
    const itemChildIndex = getFlowItemChildrenIndex(state);

    const defaultX = 10;
    const defaultY = 10;

    if (parentId == null) {
        return { x: defaultX, y: defaultY };
    }
    const parentNode: ?FlowItem = state.flowItems.byId[parentId];
    if (parentNode == null) {
        return { x: defaultX, y: defaultY };
    }

    const siblingIds = itemChildIndex[parentId];
    const siblingCount = siblingIds != null ? siblingIds.length : 0;

    let x = parentNode.x + 100 * siblingCount;
    let y = parentNode.y + 130;

    let isFirstSiblingUnderParentNode = true;

    if (siblingCount > 0) {
        for (let id of siblingIds) {
            if (state.flowItems.byId[id].x < parentNode.x + 100) {
                isFirstSiblingUnderParentNode = false;
                break;
            }
        }
    }

    if (isFirstSiblingUnderParentNode) {
        x = parentNode.x;
    }

    return { x, y };
};

//////////////// PERMISSION HELPERS ///////////////////////////

// Get permissions for a FlowItem and the Flow it belongs to.
// You must also pass all flow items for that flow (so we can see if any of them are running).
//
// We also have a reselect available higher in this file, if you want this for the current flow+flowItem.
// reselect -> getSelectedFlowAndItemPermissions(state)
export const getFlowAndItemPermissions = (
    flowItem: ?FlowItem,
    flow: ?Flow,
    allFlowItemsForFlow: ?Array<FlowItem>,
    itemErrors: FlowErrorsByItemId,
    flowCannedReportErrors: FlowErrorsByItemId,
    roles: Array<string>,
    userId: number,
    hasTapadTradeDesk: boolean,
    enabledFeatures: Array<String>
): FlowAndItemPermissions => {
    const isFlowAnyErrors = isFlowAnyErrorsWithExceptions(itemErrors, allFlowItemsForFlow, flowCannedReportErrors);

    if (flowItem == null && flow != null && allFlowItemsForFlow != null) {
        return {
            item: nullFlowItemPermissions,
            flow: getFlowPermissions(flow, allFlowItemsForFlow, isFlowAnyErrors, roles, userId, hasTapadTradeDesk),
        };
    }
    if (flowItem == null || flow == null || allFlowItemsForFlow == null) {
        return {
            item: nullFlowItemPermissions,
            flow: nullFlowPermissions,
        };
    }
    const isFlowSaving = flow.isSaving != null && flow.isSaving == 1;
    const isTypeEmpty = flowItem.FlowItemType == "empty";
    const isTypeExpand = flowItem.FlowItemType == "houseExpand";
    const isTypeScript = flowItem.FlowItemType == "script" || flowItem.FlowItemType == "scriptdbui";
    const isRunning = flowItem.IsRunning;
    const flowItemErrors = itemErrors[flowItem.FlowItemId] || [];
    const itemHasErrors =
        isTypeScript && flowItemErrors.find(x => x === "No Script has been entered.") == undefined
            ? false
            : (flowItemErrors || flowCannedReportErrors[flowItem.FlowItemId] || []).length > 0;
    const isActive = flowItem.IsActive;
    const isFlowSaved = flowItem.FlowItemId > -1 || (flow && flow.FlowBaseType > 0);

    const flowPermissions = getFlowPermissions(
        flow,
        allFlowItemsForFlow,
        isFlowAnyErrors,
        roles,
        userId,
        hasTapadTradeDesk,
        enabledFeatures
    );
    const canFlowEdit = flowPermissions.canEdit;

    return {
        item: {
            canRename: canFlowEdit && !isRunning && !isFlowSaving && !isTypeEmpty,
            canRemove: canFlowEdit && !isRunning && !isFlowSaving && !isTypeEmpty,
            canEdit: canFlowEdit && !isRunning && !isFlowSaving,
            canVisitEditPage: !isTypeEmpty && !isTypeExpand,
            canCalculate:
                flowPermissions.canCalculate &&
                !isRunning &&
                !isFlowSaving &&
                !itemHasErrors &&
                !flow.hasUnsavedChanges,
            canCancel: false,
            canAdminCancelDeploy: false,
            canSaveAndCalculate:
                flowPermissions.canSave &&
                flowPermissions.canCalculate &&
                !isRunning &&
                !isFlowSaving &&
                !itemHasErrors &&
                flow.FlowDateCreated != "" &&
                isActive,
            canExecuteItemOnly:
                flowPermissions.canSave && !isRunning && !isFlowSaving && !itemHasErrors && isActive && isFlowSaved,
            canDuplicate: canFlowEdit && !isTypeEmpty && FlowConstants.allowsCopy.includes(flowItem.FlowItemType),
            canViewQA: !isRunning && !isFlowSaving && flowItem.HasQATable,
            isQAEverPossible: FlowConstants.hasQA.includes(flowItem.FlowItemType),
        },
        flow: flowPermissions,
    };
};

// Get permissions for a Flow.
// You must also pass all flow items for that flow (so we can see if any of them are running).
//
// We also have a reselect available higher in this file, if you want this for the current flow.
// reselect -> getSelectedFlowPermissions(state)
export const getFlowPermissions = (
    flow: ?Flow,
    allFlowItemsForFlow: ?Array<FlowItem>,
    isFlowAnyErrors: boolean,
    roles: Array<string>,
    userId: number,
    hasTapadTradeDesk: boolean,
    enabledFeatures: Array<string>
): FlowPermissions => {
    if (flow == null || allFlowItemsForFlow == null) {
        return nullFlowPermissions;
    }
    allFlowItemsForFlow = allFlowItemsForFlow.filter(item => item.FlowItemType != "flowDescription");
    const isSuperAdmin = roles && Object.keys(roles).includes("Super Admin");
    // otherUserAccessFlow for ticket #6503 to allow other user to save and
    let otherUserAccessFlow = false;
    otherUserAccessFlow =
        flow && userId == flow.FlowCreatedBy
            ? true
            : enabledFeatures && roles && enabledFeatures.includes("access-run-other-user-flow");

    const isLocked = flow.IsLocked;
    const isFlowSaving = flow.isSaving != null && flow.isSaving == 1;
    const isAnyFlowItemRunning = allFlowItemsForFlow.filter(x => x.IsRunning).length > 0;
    const isAnyFlowItemPending = allFlowItemsForFlow.filter(x => x.IsRunning > 1 && x.IsRunning < 5).length > 0;
    const isInCancelProcess = allFlowItemsForFlow.filter(x => x.IsRunning == 4).length > 0;
    const isSubmitted = allFlowItemsForFlow.filter(x => x.IsRunning == 1).length > 0;
    const isAnyItemActive = allFlowItemsForFlow.filter(x => x.IsActive == true).length > 0;
    const isSameUser = flow.FlowCreatedBy == userId || otherUserAccessFlow || hasTapadTradeDesk; // if Tapad flow, same user does not matter
    const isLoadingFlowData = flow.isLoadingFlowData;
    const isAnyFlowItemDeploying = allFlowItemsForFlow.filter(x => x.IsRunning == 5).length > 0;

    return {
        canRevert:
            !isLocked && !isFlowSaving && !isAnyFlowItemRunning && flow.FlowId > 0 && flow.hasUnsavedChanges == true,
        canSave: !isLocked && !isFlowSaving && !isAnyFlowItemRunning && isSameUser && !isLoadingFlowData,
        canSaveAs: !isFlowSaving && !isLoadingFlowData,
        canEdit: !isLocked && !isFlowSaving && !isAnyFlowItemRunning,
        canCalculate:
            !isLocked &&
            !isFlowSaving &&
            !isAnyFlowItemRunning &&
            flow.FlowId > 0 &&
            !isFlowAnyErrors &&
            isAnyItemActive,
        canCancel: !isLocked && isAnyFlowItemPending,
        isInCancelProcess,
        canAdminCancelDeploy: isAnyFlowItemDeploying,
        isSubmitted,
        canDelete:
            ((flow.FlowCreatedBy == userId && !isLocked) || isSuperAdmin) &&
            !isAnyFlowItemRunning &&
            !isFlowSaving &&
            !flow.isRenaming,
    };
};

const nullFlowItemPermissions = {
    canRename: false,
    canEdit: false,
    canVisitEditPage: false,
    canRemove: false,
    canCalculate: false,
    canCancel: false,
    canDuplicate: false,
    canViewQA: false,
    isQAEverPossible: false,
    canSaveAndCalculate: false,
    canExecuteItemOnly: false,
    canAdminCancelDeploy: false,
};

export const nullFlowPermissions = {
    canRevert: false,
    canSave: false,
    canSaveAs: false,
    canCalculate: false,
    canEdit: false,
    canCancel: false,
    canDelete: false,
    isInCancelProcess: false,
    isSubmitted: false,
    canAdminCancelDeploy: false,
};

// HouseExpand Error checking (It doesn't have a subtable, so it doesn't even have its own reducer)

const houseErrorChecker = (items: Array<FlowItem>, relations: Array<FlowRelation>) => {
    const errorsById = {};
    const houseItems = items.filter(x => x.FlowItemType.toLowerCase() == "houseexpand");
    for (const item of houseItems) {
        errorsById[item.FlowItemId] = validateHouseExpand(item, relations);
    }
    return errorsById;
};

const getHouseExpandErrorsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    houseErrorChecker
);

const getHouseExpandErrorsForAllFlows = createSelector(
    state => getFlowItemsArray(state),
    state => getFlowRelationsForAllFlows(state),
    houseErrorChecker
);

const validateHouseExpand = (flowItem: FlowItem, flowRelations: Array<FlowRelation>): Array<string> => {
    const topRelations = flowRelations.filter(z => z.ParentFlowItemId == 0 && z.ChildFlowItemId == flowItem.FlowItemId);
    const allRelations = flowRelations.filter(z => z.ChildFlowItemId == flowItem.FlowItemId);
    if (topRelations.length > 0 || allRelations.length == 0) {
        return ["Dedupe items must have a parent item assigned."];
    }
    return [];
};

const isFlowAnyErrorsWithExceptions = (
    itemErrors: FlowErrorsByItemId,
    allFlowItemsForFlow: ?Array<FlowItem>,
    flowCannedReportErrors: FlowErrorsByItemId
) => {
    let isFlowAnyErrors = flowCannedReportErrors && Object.keys(flowCannedReportErrors).length > 0;

    if (!isFlowAnyErrors && Object.keys(itemErrors).length > 0) {
        const scriptItems = allFlowItemsForFlow
            .filter(x => x.IsActive && x.FlowItemType == "script")
            .map(x => x.FlowItemId);
        const otherItems = allFlowItemsForFlow
            .filter(x => x.IsActive && x.FlowItemType != "script")
            .map(x => x.FlowItemId);
        let filteredItemErrors: FlowErrorsByItemId = [];

        scriptItems.forEach(x => {
            if (itemErrors[x]?.includes("No Script has been entered.")) {
                filteredItemErrors[x] = itemErrors[x];
            }
        });
        otherItems.forEach(x => {
            filteredItemErrors[x] = itemErrors[x];
        });

        isFlowAnyErrors = Object.keys(filteredItemErrors).length > 0;
    }

    return isFlowAnyErrors;
};
